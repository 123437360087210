import { trigger, transition, style, animate, state } from '@angular/animations';

export const openCloseMenuFromTop = trigger('openClose', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateY(-10%)',
            boxShadow: 'none',
        }),
        animate(
            150,
            style({
                opacity: 1,
                transform: 'translateY(0)',
                boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.3)',
            })
        ),
    ]),
    transition(':leave', [
        animate(
            150,
            style({
                opacity: 0,
                transform: 'translateY(-10%)',
                boxShadow: 'none',
            })
        ),
    ]),
]);

export const growHeight = (h: number) =>
    trigger('growHeight', [
        transition(':enter', [
            style({
                height: 0,
                'min-height': 0,
            }),
            animate(
                150,
                style({
                    height: h,
                    'min-height': h,
                })
            ),
        ]),
    ]);

export const enterFromBottom = trigger('enterFromBottom', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateY(100%)',
        }),
        animate(
            150,
            style({
                opacity: 1,
                transform: 'translateY(0)',
            })
        ),
    ]),
    transition(':leave', [
        animate(
            150,
            style({
                opacity: 0,
                transform: 'translateY(100%)',
            })
        ),
    ]),
]);

export const leaveFromTop = trigger('leaveFromTop', [
    state('visible', style({ opacity: 1, transform: 'translateY(0)' })),
    state('hidden', style({ opacity: 0, transform: 'translateY(-100%)' })),
    transition('visible => hidden', animate('150ms ease-in')),
    transition('hidden => visible', animate('150ms ease-out')),
]);

export const fadeIn = trigger('fadeIn', [
    transition(':enter', [style({ opacity: 0 }), animate(1500, style({ opacity: 1 }))]),
]);
